import { Transition } from '@headlessui/react'
import React, { useState } from 'react'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { twMerge } from 'tailwind-merge'
import useWindowSize from '../../../../shared/hooks/use-window-size'

interface ReadMoreProps {
  content: string
  postDetailsPath: string
}

export const PostAnnounceContent = ({ content, postDetailsPath }: ReadMoreProps) => {
  const [isShowing, setIsShowing] = useState(false)
  const { width } = useWindowSize()
  const { t } = useTranslation()
  const isDesktop = width <= 768
  const MAX_CONTENT_LENGTH = 360
  const MAX_CONTENT_LENGTH_MOBILE = 125

  const slicedContent =
    content.slice(0, isDesktop ? MAX_CONTENT_LENGTH_MOBILE : MAX_CONTENT_LENGTH).slice(0, -4) +
    '...</p> '

  if (content.length < MAX_CONTENT_LENGTH) {
    return (
      <Link href={postDetailsPath} shallow>
        <div
          className="preview-image-wrapper break-words text-sm text-darkblue/90 lg:text-base"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </Link>
    )
  }

  return (
    <>
      {!isShowing && (
        <Link href={postDetailsPath} shallow>
          <div
            className={twMerge(
              'preview-image-wrapper inline break-words text-sm text-darkblue/90 lg:text-base last:[&>p]:inline',
              isDesktop && 'cursor-pointer',
            )}
            onClick={isDesktop ? () => setIsShowing(isShowing => !isShowing) : undefined}
            dangerouslySetInnerHTML={{ __html: slicedContent }}
          />
        </Link>
      )}
      <Transition
        show={isShowing}
        enter="transition ease-linear duration-300 transform"
        enterFrom="-translate-y-5 opacity-30"
        enterTo="translate-y-0 opacity-100"
      >
        <Link href={postDetailsPath} shallow>
          <div
            className={`preview-image-wrapper inline break-words text-sm text-darkblue/90 lg:text-base`}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </Link>
      </Transition>
      {!isShowing && (
        <button
          className="opacity-80 transition-colors hover:text-blue hover:underline"
          onClick={() => setIsShowing(isShowing => !isShowing)}
        >
          {t('post_announce.post_show_more')}
        </button>
      )}
    </>
  )
}
