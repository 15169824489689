import useUser, { isAuthorizedUser } from 'shared/hooks/use-user'
import { PostStatusEnum } from '../enums/post-status-enum'
import { PostInterface } from '../types/post-interface'

export const useIsPostEditable = (post: PostInterface) => {
  const { user, isOwner } = useUser()

  const isAuthor = user && isAuthorizedUser(user) && post.userId === user.id
  const isApproved = post.status === PostStatusEnum.Approved
  const isEditableByOwner = isOwner && isApproved

  if (isAuthor || isEditableByOwner) {
    return true
  }

  return false
}
