import { COMMUNITY_API_PREFIX } from '../../../shared/api/constants/api-constants'
import { httpClient } from '../../../shared/api/http-client'
import { ApiPaginationResponseInterface } from 'shared/types/api-pagination-response-interface'
import { PostInterface } from '../types/post-interface'

export function getPostsByLastId(communityPath: string, lastPostId: number) {
  if (lastPostId === 0) {
    return httpClient.get<ApiPaginationResponseInterface<PostInterface>>(
      `${COMMUNITY_API_PREFIX}/${communityPath}/posts/list?pagination[limit]=10`,
    )
  }

  return httpClient.get<ApiPaginationResponseInterface<PostInterface>>(
    `${COMMUNITY_API_PREFIX}/${communityPath}/posts/list?pagination[startFrom]=${lastPostId}&pagination[order]=desc&pagination[limit]=10`,
  )
}

export function getTopicPostsByLastId(
  communityPath: string,
  topicPath: string,
  lastPostId: number,
) {
  if (lastPostId === 0) {
    return httpClient.get<ApiPaginationResponseInterface<PostInterface>>(
      `${COMMUNITY_API_PREFIX}/${communityPath}/topic/${topicPath}/posts/list?pagination[limit]=10`,
    )
  }

  return httpClient.get<ApiPaginationResponseInterface<PostInterface>>(
    `${COMMUNITY_API_PREFIX}/${communityPath}/topic/${topicPath}/posts/list?pagination[startFrom]=${lastPostId}&pagination[order]=desc&pagination[limit]=10`,
  )
}

export function approvePost(communityPath: string, postId: number[]) {
  return httpClient.post(`${COMMUNITY_API_PREFIX}/${communityPath}/moderation/posts/approve`, {
    ids: postId,
  })
}

export function declinePost(communityPath: string, postId: number[]) {
  return httpClient.post(`${COMMUNITY_API_PREFIX}/${communityPath}/moderation/posts/decline`, {
    ids: postId,
  })
}

export function getAllPendingPostsByLastId(
  communityPath: string,
  lastPostId: number,
  filter: string,
  controller: AbortController,
) {
  if (lastPostId === 0) {
    return httpClient.get<ApiPaginationResponseInterface<PostInterface>>(
      `${COMMUNITY_API_PREFIX}/${communityPath}/moderation/posts/list?pagination[limit]=10${
        filter ? `&filter[searchBy]=${filter}` : ''
      }`,
      {
        signal: controller.signal,
      },
    )
  }

  return httpClient.get<ApiPaginationResponseInterface<PostInterface>>(
    `${COMMUNITY_API_PREFIX}/${communityPath}/moderation/posts/list?pagination[startFrom]=${lastPostId}&pagination[order]=desc&pagination[limit]=10${
      filter ? `&filter[searchBy]=${filter}` : ''
    }`,
    {
      signal: controller?.signal,
    },
  )
}

export function getOwnPendingPostsByLastId(communityPath: string, lastPostId: number) {
  if (lastPostId === 0) {
    return httpClient.get<ApiPaginationResponseInterface<PostInterface>>(
      `${COMMUNITY_API_PREFIX}/${communityPath}/my-pending-posts/list?pagination[limit]=10`,
    )
  }

  return httpClient.get<ApiPaginationResponseInterface<PostInterface>>(
    `${COMMUNITY_API_PREFIX}/${communityPath}/my-pending-posts/list?pagination[startFrom]=${lastPostId}&pagination[order]=desc&pagination[limit]=10`,
  )
}

export function deletePost(postId: number) {
  return httpClient.delete<void>(`/api/community/post/${postId}`)
}
