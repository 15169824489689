import React from 'react'

function ImageIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg width="28" height="26" viewBox="0 0 28 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M23.9874 0.287598H3.90905C2.10723 0.287598 0.609192 1.78563 0.609192 3.634V20.366C0.609192 22.2144 2.10723 23.7124 3.90853 23.7124H23.9869C25.8353 23.7124 27.3333 22.2144 27.3333 20.366V3.634C27.3338 1.78563 25.8803 0.287598 23.9874 0.287598ZM6.41885 3.634C7.805 3.634 8.92865 4.75766 8.92865 6.1438C8.92865 7.52994 7.85153 8.6536 6.41885 8.6536C4.98618 8.6536 3.90905 7.52994 3.90905 6.1438C3.90905 4.75766 5.07925 3.634 6.41885 3.634ZM23.9352 19.9268C23.7888 20.1987 23.5064 20.366 23.1979 20.366H4.89781C4.7434 20.366 4.59201 20.3233 4.4604 20.2425C4.3288 20.1618 4.22212 20.0461 4.15219 19.9085C4.08219 19.7708 4.05166 19.6165 4.06397 19.4626C4.07628 19.3086 4.13095 19.1611 4.22194 19.0363L7.88207 14.0167C8.03977 13.7987 8.29075 13.6732 8.55741 13.6732C8.82408 13.6732 9.07589 13.8008 9.23349 14.0169L10.9307 16.3443L15.8092 9.07106C15.9665 8.79477 16.228 8.6536 16.5051 8.6536C16.7822 8.6536 17.0463 8.79331 17.2011 9.02614L23.8939 19.0653C24.0659 19.3202 24.0816 19.6497 23.9352 19.9268Z" />
    </svg>
  )
}

export default ImageIcon
