import React from 'react'
import { useSlate } from 'slate-react'
import { isMarkActive, toggleMark } from '../community/hooks/slate-editor-utils'

export default function MarkButton({
  format,
  label,
  children,
}: React.PropsWithChildren<{ format: string; label: string }>) {
  const editor = useSlate()
  const active = isMarkActive(editor, format)
  return (
    <button
      className={`relative z-10 inline-flex items-center px-2 py-1 ${
        active ? 'fill-black' : 'fill-bluegray hover:fill-black'
      } `}
      onMouseDown={event => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
      title={label}
    >
      {children}
    </button>
  )
}
