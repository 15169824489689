import useSWR from 'swr'
import { COMMUNITY_API_PREFIX } from 'shared/api/constants/api-constants'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import { PostInterface } from 'modules/community/types/post-interface'

export interface UsePostProps {
  communityPath: string
  topicPath: string
  postPath: string
}

export function usePost({ communityPath, topicPath, postPath }: UsePostProps) {
  const { user } = useUser()

  const { fetcher } = useApiWrapperWithErrorValidation<RequestMethodsEnum.get, PostInterface>({
    method: RequestMethodsEnum.get,
  })

  return useSWR<PostInterface>(() => {
    if (communityPath && user) {
      return `${COMMUNITY_API_PREFIX}/${communityPath}/topic/${topicPath}/post/${postPath}`
    }
    return null
  }, fetcher)
}
