import { useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { useHover } from '../../../../shared/hooks/use-hover'
import LikeIcon from 'shared/icons/like-icon'
import { debounce } from 'shared/utils/debounce'
import { LikeDetailPopover } from './like-detail-popover'

export interface LikeSectionProps {
  className?: string
  isLiked: boolean
  likesCount: number
  handleLikeClick: () => void
  postId: number
}

const LikeButton = ({
  className,
  isLiked,
  likesCount,
  handleLikeClick,
  postId,
}: LikeSectionProps) => {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null)
  const { hovered, ref: hoverRef } = useHover()

  return (
    <div
      className={twMerge(
        'group relative inline-flex gap-2 rounded-md px-1.5 py-2 text-bluegray hover:bg-blue/20',
        className,
      )}
    >
      <button
        className="inline-flex cursor-pointer items-center gap-2"
        onClick={debounce(handleLikeClick, 300)}
      >
        <LikeIcon
          className={twMerge(
            'h-6 w-6 fill-none stroke-bluegray transition-colors group-hover:stroke-blue',
            isLiked && 'fill-blue stroke-blue',
          )}
        />
      </button>
      {likesCount > 0 && (
        <div ref={hoverRef}>
          <span ref={setReferenceElement} className="cursor-pointer">
            {likesCount}
          </span>
          {hovered && (
            <LikeDetailPopover
              postId={postId}
              likesCount={likesCount}
              referenceElement={referenceElement}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default LikeButton
