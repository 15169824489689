import React from 'react'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import CommentIcon from '../../../../shared/icons/comment-icon'
import { LocaleEnum } from 'shared/enums/locale-enum'
import EllipseIcon from 'shared/icons/ellipse-icon'
import LikeButton from 'modules/community/components/like/like-button'
import { PostAnnounceContent } from 'modules/community/components/post-announce/post-announce-content'
import PostModalEdit from 'modules/community/components/post-modal-edit'
import { PostStatusEnum } from 'modules/community/enums/post-status-enum'
import { useIsPostEditable } from 'modules/community/hooks/use-is-post-editable'
import { MemberInterface } from 'modules/community/types/member-interface'
import { PostInterface } from 'modules/community/types/post-interface'
import { timeSince } from 'modules/post/utils/time-utils'

type PostAnnounceProps = {
  post: PostInterface
  author?: MemberInterface
  commentsCount?: number
  isClickable?: boolean
  deletePost: () => void
  handleLikeClick?: () => void
  dashboardLocale?: LocaleEnum
}

function PostAnnounce({
  post,
  author,
  commentsCount = 0,
  isClickable = true,
  deletePost,
  handleLikeClick,
  dashboardLocale,
}: PostAnnounceProps) {
  const { t } = useTranslation()
  const router = useRouter()
  const { communityPath } = router.query
  const topicPath = `/community/${communityPath}/${post.topic.path}`
  const postDetailsPath = `${topicPath}/${post.path}`
  const postDetailsPathWithCommentAnchor = `${topicPath}/${post.path}#Comments`
  const isPostEditable = useIsPostEditable(post)

  return (
    <div className="mb-8 w-full rounded-lg border border-[#eceef1] bg-white px-7 py-3 pb-1 shadow-[0px_4px_20px_0px_#F2F4F5] transition-shadow hover:shadow-[0px_4px_25px_0px_rgb(217,221,223)] lg:px-10 lg:py-4 lg:pb-2">
      <div className="mb-2.5 flex justify-between md:mb-3.5">
        <div className="flex flex-wrap items-center gap-2.5">
          {author && (
            <Link
              href={postDetailsPath}
              className="flex items-center gap-2.5 text-bluegray"
              shallow
            >
              <img
                src={author.avatarUrl}
                alt={`${author.firstName} ${author.lastName}`}
                className="h-10 w-10 rounded-full"
              />
              <div className="text-darkblue">{author.displayName}</div>
              <EllipseIcon />
            </Link>
          )}
          <div className="bluegray lg:block">
            <Link href={postDetailsPath} shallow>
              {timeSince(new Date(post.createdAt), dashboardLocale)}
            </Link>{' '}
            {t('community.pages_on')}{' '}
            <Link className="font-semibold text-darkblue" href={topicPath} shallow>
              #{post.topic.name}
            </Link>
          </div>
        </div>
        {isPostEditable && <PostModalEdit post={post} deletePost={deletePost} />}
      </div>
      <Link href={postDetailsPath} shallow>
        <h2 className="community-post-title pb-2 md:pb-3">{post.title}</h2>
      </Link>
      <PostAnnounceContent content={post.content} postDetailsPath={postDetailsPath} />
      {post.status === PostStatusEnum.Approved && (
        <div className="mt-1 flex items-center gap-3">
          {handleLikeClick && (
            <LikeButton
              isLiked={post.isLiked}
              likesCount={post.likesCount}
              handleLikeClick={handleLikeClick}
              postId={post.id}
            />
          )}
          <Link
            href={postDetailsPathWithCommentAnchor}
            className="group flex items-center gap-2 rounded-md px-1.5 py-2 transition-colors hover:bg-blue/20"
          >
            <CommentIcon className="h-6 w-6 transition-colors group-hover:fill-blue" />
            {commentsCount > 0 ? commentsCount : ''}
          </Link>
        </div>
      )}
    </div>
  )
}

export function PostAnnounceSkeleton() {
  return (
    <div className="mb-10 animate-pulse overflow-hidden rounded-lg bg-white">
      <div className="w-full px-5 py-5 lg:px-16 lg:py-10">
        <div className="mb-4 flex items-center gap-2">
          <div className="h-6 w-6 rounded-full bg-gray" />
          <div className="h-4 w-14 rounded-sm bg-gray" />
          <div className="h-4 w-14 rounded-sm bg-gray" />
        </div>
        <div className="mb-2 h-7 rounded-sm bg-gray" />
        <div className="mb-5 h-7 w-3/4 rounded-sm bg-gray" />
        {Array.from(Array(6).keys()).map(index => (
          <div className="mb-3 h-3 rounded-sm bg-gray" key={index} />
        ))}
      </div>
      <hr className="mx-5 px-5 text-gray lg:mx-0" />
      <div className="flex w-full justify-between px-5 py-5 lg:px-16 lg:py-10">
        <div className="flex gap-4">
          <div className="flex items-center gap-2">
            <div className="h-6 w-6 rounded-full bg-gray" />
            <div className="h-4 w-14 rounded-sm bg-gray" />
          </div>
          <div className="flex items-center gap-2">
            <div className="h-6 w-6 rounded-full bg-gray" />
            <div className="h-4 w-14 rounded-sm bg-gray" />
          </div>
        </div>
        <div className="">
          <div className="h-6 w-20 rounded-sm bg-gray" />
        </div>
      </div>
    </div>
  )
}

export default PostAnnounce
