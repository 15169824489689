import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import React, { Fragment, useState } from 'react'
import { useTranslation } from 'next-i18next'
import Confirmation from '../../../shared/components/confirmation-modal'
import ThreeDotsNavIcon from '../../../shared/icons/three-dots-nav-icon'
import UpdatePost from 'modules/post/components/update-post'
import { PostInterface } from '../types/post-interface'

type PostModalEditProps = {
  post: PostInterface
  deletePost: () => void
}

const PostModalEdit = ({ post, deletePost }: PostModalEditProps) => {
  const [isUpdateModalOpened, setIsUpdateModalOpened] = useState(false)
  const [isRemovePostOpened, setIsRemovePostOpened] = useState(false)

  const { t } = useTranslation()

  function resetRemovablePost() {
    setIsRemovePostOpened(false)
  }

  function resetUpdatePost() {
    setIsUpdateModalOpened(false)
  }

  async function removePost() {
    await deletePost()
    setIsRemovePostOpened(false)
  }

  return (
    <>
      <Menu as="div" className="relative ml-auto flex items-center">
        <MenuButton>
          <ThreeDotsNavIcon className="text-darkblue/80 hover:text-darkblue" />
        </MenuButton>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <MenuItems className="absolute right-0 top-0 z-10 w-36 origin-center rounded-md bg-lightblue shadow-lg">
            <MenuItem as="div" className="px-1 py-1">
              {({ focus }) => (
                <button
                  className={`${
                    focus ? 'text-darkblue' : 'text-darkblue/40'
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  onClick={() => setIsRemovePostOpened(true)}
                >
                  {t('settings.form.delete.label')}
                </button>
              )}
            </MenuItem>
            <MenuItem as="div" className="px-1 py-1">
              {({ focus }) => (
                <button
                  className={`${
                    focus ? 'text-darkblue' : 'text-darkblue/40'
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  onClick={() => setIsUpdateModalOpened(true)}
                >
                  {t('settings.form.update.label')}
                </button>
              )}
            </MenuItem>
          </MenuItems>
        </Transition>
      </Menu>
      {isUpdateModalOpened && (
        <UpdatePost post={post} onCloseModal={resetUpdatePost} onUpdateSuccess={resetUpdatePost} />
      )}
      {isRemovePostOpened && (
        <Confirmation
          onConfirm={removePost}
          onCloseModal={resetRemovablePost}
          confirmationText={t('settings.form.delete_post_confirmation.label')}
        />
      )}
    </>
  )
}

export default PostModalEdit
